import React, { useState } from "react";
import { Input, ConfigProvider } from 'antd';
import { useTranslation } from "react-i18next";
import "./components.css";
const { Search } = Input;

export default function SearchBar() {
const [token, setToken] = useState('');
  
  const onSearch = async(value) => {
    
      const inv_number = value.replace(/\s/g, "");
      if (inv_number.length === 12 || inv_number.length === 13) {
          window.location.href = `/invoice/${inv_number}`;
      }
      if (inv_number.length === 7 || inv_number.length === 8 || inv_number.length === 9) {
        window.location.href = `/violations/${inv_number.toUpperCase()}`;
      }
    
  };

  const { t } = useTranslation();
  return (
    <ConfigProvider theme = {
      {
        "components": {
          "Input": {
            "addonBg": "rgb(255, 255, 255)",
            "hoverBorderColor": "rgb(16, 211, 230)",
            "activeBorderColor": "rgb(1, 167, 198)"
          }
        }
      }
    }>
    
    <Search
      placeholder={t("SEARCH")}
      allowClear
      onSearch={onSearch}
    />
    
    
    </ConfigProvider>
    
  );
}
