import React, { Component } from "react";
import { numberWithSpaces, withRouter, API_KEY } from "../GlobalFunctions"
import { LoadingOutlined, CameraOutlined, VideoCameraOutlined, CheckCircleOutlined, } from '@ant-design/icons';
import { Spin, Collapse, Form, Input, message, Table, Modal, ConfigProvider, } from 'antd'; 
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
//import ReCAPTCHA from "react-google-recaptcha";
//import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";

const antIcon = ( <LoadingOutlined style={{ fontSize: 24, }} spin /> );

export class RexgenEvidence extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            requestFailed: false,
            data: null,
            token: '',
            evidenceView: false,
            evidence: null,
            evidenceView: false,
            evidence_photo: null,
        };
    };

    componentDidMount() {
        this.getData();
    }; 

    componentDidUpdate() {
    
    };

    getData = () => {
        fetch('https://smartpay.tj/subapi/payler/safecity/evidence/' + this.props.params.ticketNumber, 
            { 
                method: "GET", 
                headers : {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                    'x-app-token': API_KEY(),
                }
            }).then((response) => {
                if(response.status === 200){
                    return response.json();     
                }else {
                    console.log(new Error(response.status))
                    this.setState({ isLoaded:true, requestFailed: true, })
                }
              })
                  .then((responseJson) => {
                    this.setState({ evidence : responseJson, isLoaded: true, evidenceView: true,});
                    
                  });
      };

    getPhoto = (row) => {
        sessionStorage.setItem('proof', JSON.stringify({'type': row.type, 'link':row.link}));
        window.location.href = `/violations/${this.props.params.plateNumber}/evidence/${this.props.params.ticketNumber}/proof`;
    };

render () {
    const { isLoaded, data, evidence, evidence_photo } = this.state;
    const { translation } = this.props;
    const evidence_columns = [
        {
            title: "Акси ҳодиса",
            dataIndex: "type",
            //width: "5%",
            align: "center",
            key: "type",
            render: (type, row) => {
              return <div key={uuidv4()} className="cursor-pointer text-[24px]" onClick={() => {this.getPhoto(row)}} >{type==='photo' ? <CameraOutlined /> :<VideoCameraOutlined />}</div>;
            },
        },
        {
          title: "Сана",
          dataIndex: "date",
          //width: "10%",
          align: "center",
          key: "date",
          render: (date, row) => {
            return <div key={uuidv4()} className="cursor-pointer text-[14px]" onClick={() => {this.getPhoto(row)}}>{date}</div>;
          },
        },
      ];

return (
    <ConfigProvider theme={{
        "components": {
          "Button": {
            "colorPrimary": "rgb(44,46,49)"
          }
        }
      }}>
    <div className="pt-4">{!isLoaded ?
      <div className="flex w-full h-[80vh] justify-center items-center">
        <Spin size="large" indicator={antIcon} />
      </div> : 
      <>
        <div className="w-full flex justify-center sm:text-[16px] md:text-[20px] py-2">{translation('vehicle_plate')}</div>
        <div className="w-full flex justify-center py-4">
                <div className="rounded-[5px] border-[2px] border-black px-4">    
                    <div className="text-[24px] font-bold tracking-wider">{this.props.params.plateNumber}</div>
                </div>
        </div>
        <div className="w-full flex justify-center sm:text-[16px] md:text-[20px] py-2">{translation('evidence')} {this.props.params.ticketNumber} </div>
        <Table 
            className="md:pl-4"
            dataSource={evidence ? evidence.evidence : null}
            columns={evidence_columns} 
            pagination={false}
            size="small"
        >
        </Table>
    </>
    }</div>
    </ConfigProvider>
);    
};
}

export default withRouter(RexgenEvidence);