import React, { Component } from "react";
import { numberWithSpaces, withRouter, API_KEY } from "../GlobalFunctions"
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Collapse, Form, Input, message } from 'antd'; 
import { Link } from "react-router-dom";
//import ReCAPTCHA from "react-google-recaptcha";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import AuthCode from 'react-auth-code-input';
import { QRCode } from 'react-qrcode-logo';
import { height } from "@fortawesome/free-regular-svg-icons/faAddressBook";

const antIcon = ( <LoadingOutlined style={{ fontSize: 24, }} spin /> );

export class GazpromNeft extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            requestFailed: false,
            data: null,
            merchantId: null,
            catId: null,
            summ: 100,
            phone: '',
            isRecaptcha: null,
            token: '',
            refreshReCaptcha: false,
            isPhoneEntered: null,
            count: null,
        };
    };

    componentDidMount() {
        this.get_data();
    }; 

    componentDidUpdate() {
    
    };

    setTokenFunc = (getToken) => {
        this.setState({token: getToken});
    };

    onFinish=()=>{
        //console.log(`Phone: ${this.state.phone}, ServiceId: ${this.props.params.serviceId}, summ: ${this.state.summ}, CatId: ${this.props.params.catId}`)
        //this.setState({isRecaptcha: false});
        fetch(`https://smartpay.tj/api/v1/invoices/gn/new`, 
            { 
                method: "POST", 
                headers : {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                    'x-app-token': API_KEY(),
                },
                body: JSON.stringify({
                    'service_id': parseInt(this.props.params.serviceId),
                    'form_id': parseInt(this.props.params.formId), 
                    'phone': this.state.phone,
                    'qty': this.state.count,
                    'price': this.state.summ
                  })
            })
        
            .then((response) => {
              if(response.status === 200){
                  return response.json();     
              }else {
                  this.setState({ requestFailed: true })
                  message.error('Дархост қайд нашуд');
                  //window.location.href = 'https://smartpay.tj/404'
              }
            })
                .then((responseJson) => {
                  window.location.href = `https://smartpay.tj/invoice/${responseJson.invoice_id}`
                });
    }

    get_data = () => {
        fetch(`https://smartpay.tj/api/v1/services/gazpromneft/${this.props.params.formId}`, 
            { 
                method: "GET", 
                headers : {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                    'x-app-token': API_KEY(),
                }
            })
        
            .then((response) => {
              if(response.status === 200){
                  return response.json();     
              }else {
                  this.setState({ requestFailed: true })
                  //window.location.href = 'https://smartpay.tj/404'
              }
            })
                .then((responseJson) => {
                  this.setState({ data : responseJson, isLoaded: true });
                });
    }

    render () {
        const { isLoaded, data, summ, phone, isRecaptcha, isPhoneEntered, count } = this.state;
        const { Panel } = Collapse;
        const { translation } = this.props;

        return(
            <div id = "InvoiceContainer" style={{textAlign: "-webkit-center"}} className="block bg-white text-center">
                {!isLoaded ?<div className="flex w-full h-[80vh] justify-center items-center">
                    <Spin size="large" indicator={antIcon} />
                </div>
                :<>
                <div className="block md:w-[55%] mt-4">
                    <div className="flex my-4 justify-center items-center">
                        <img alt='' className="" src={data.logo}></img>
                    </div>
                    <div className="w-[75%] block  text-center">
                        <div className="mx-2 sm:text-[14px] md:text-[18px] upper font-semibold">{data.form_name}</div>
                        {/*<div className="mx-2 sm:text-[11px] md:text-[13px] text-center py-[5px]" >
                        {data.address} {`${translation('TEL')} ${data.phone} ${translation('EMAIL')} ${data.email}`} 
                        </div>*/}
                    </div>
                </div>

                { this.props.params.serviceId
                ? <>
                    {data.category.map ((service, _)=>(
                          service.id === parseInt(this.props.params.serviceId) && 
                          <>
                          <Form id="appform" className="" form={this.props.form} onFinish={this.onFinish}>
                          <div className="mx-4 md:w-1/3">
                            <div className="flex mt-4">
                                <div className="w-[30%] text-left text-sky-600">Сӯзишворӣ</div>
                                <div className="w-[70%] text-[18px] text-sky-600 text-right">{service.name} смн.</div>
                            </div>
                            <div className="flex mt-4">
                                <div className="w-[30%] text-left text-sky-600">Нархи 1 л.</div>
                                <div className="w-[70%] text-[18px] text-sky-600 text-right text-right">{numberWithSpaces(service.price.toFixed(2))} смн.</div>
                            </div>
                            <div className="flex items-center mt-4">
                                <div className="w-[50%] text-left text-sky-600">Маблағи пардохти шумо</div>
                                <div className="w-[50%] text-right">
                                <Form.Item name='summ' className="m-0">
                                {/* <Input
                                    onChange={(e)=>{this.setState({summ: e.target.value})}}
                                    defaultValue={1}
                                    className="text-center bg-white border px-2 py-1 rounded-[4px] w-1/3 mx-auto"
                                    type="text"
                                    name="summ"
                                    required
                                /> */}
                                    <div className="flex justify-between items-center text-sky-600">
                                    <div onClick={() => {
                                        if (summ > 50) {
                                            this.setState(prevState =>{
                                                return{...prevState, summ : prevState.summ - 10}
                                             });
                                             this.setState(prevState =>{
                                                return{...prevState, count : (prevState.summ - 10)/ service.price}
                                             });
                                            }
                                        }
                                    }
                                        className="w-[38px] h-[40px] bg-sky-600 active:bg-[#212462] rounded-[7px] cursor-pointer align-middle text-center text-[#ffffff] text-[24px] font-[500]"
                                    >
                                        -
                                    </div>
                                    <p className="font-[500] text-[22px] text-black">
                                        {summ} смн.
                                    </p>
                                    <div
                                        onClick={() => {
                                            this.setState(prevState =>{
                                                return{
                                                    ...prevState,
                                                    summ : prevState.summ + 10
                                                }
                                            });
                                            this.setState(prevState =>{
                                                return{...prevState, count : (prevState.summ + 10)/ service.price}
                                             });
                                        }
                                        }
                                        className="w-[38px] h-[40px] bg-sky-600 active:bg-[#212462] rounded-[7px] cursor-pointer align-middle text-center text-[#ffffff] text-[24px] font-[500]"
                                    >
                                        +
                                    </div>

                                    </div>


                                </Form.Item>
                                </div>
            
                            </div>
                            <div className="flex mt-6">
                                
                                <div className="w-full justify-center text-[22px] font-semibold">
                                Ҳамагӣ ҳаҷми сӯзишворӣ: {numberWithSpaces((summ/ service.price).toFixed(4))} л.
                                </div>
                            </div>

                            <div className="mt-4">
                                <div className="text-center font-semibold">Рақами телефон барои гирифтани смс паёмак</div>
                                <Form.Item name='phone' className="m-0" required>
                                <AuthCode 
                                    inputClassName="sm:w-[25px] sm:h-[40px] sm:text-[16px] sm:rounded-[5px] sm:mx-[1.5px] md:w-[35px] md:h-[45px] md:text-[24px] text-center md:mx-1 md:rounded-[8px] border-solid border-[1px] border-[#d6d6d6]"
                                    onChange={(value)=>{
                                            this.setState({phone: value});
                                            //console.log(value, value.length);
                                            if (value.length===9) {
                                                this.setState({isPhoneEntered: true}); 
                                            } else {this.setState({isPhoneEntered: false})};
                                        }
                                    }
                                    name="phone"
                                    length={9}
                                    allowedCharacters="numeric" 
                                />
                                </Form.Item>
                            </div>
                            <div className="mt-[40px]">
                            
                            </div>
                            <div className="mt-4">
                            <button
                                className={`${
                                    (isPhoneEntered) ? "visible sm:w-[70%] md:m-8 my-2 px-4 py-2 bg-sky-600 text-white rounded-md md:w-[200px] hover:bg-indigo-600":"hidden"
                                     
                                }`}
                                type="submit"
                                form="appform"
                                >
                                    {translation("PAY")}
                            </button>
                            </div>

                            
                          </div>
                          <GoogleReCaptchaProvider reCaptchaKey={'6LdbjS0qAAAAAFrFSN5HHvRkffPfeG-078Hbadb_'}>
                                <GoogleReCaptcha
                                    className="google-recaptcha-custom-class"
                                    onVerify={this.setTokenFunc}
                                    //refreshReCaptcha={(e)=>{this.setState({refreshReCaptcha: false})}}
                                />
                            </GoogleReCaptchaProvider>
                        </Form>
                        </>     

                            
                          
                        )

                        )
                    

                    }
                
                </> 
                :<>
                

                {data.category.map ((service,ind)=>(
                                <Link to={`${service.id}`}>
                                    <div key={ind} className="justify-center flex md:w-1/2 m-4 py-2 rounded-[10px] bg-slate-200">
                                        <div className="w-full p-2 text-sky-600 text-[20px] text-center font-semibold">
                                            <div>{`${service.name}`}</div>
                                        </div>
                                        <div className="w-full p-2 text-sky-600 text-[20px] text-center font-semibold">{`${numberWithSpaces(service.price.toFixed(2))} смн.`}</div>
                                    </div>
                                </Link>
                            )
                        )
                        }
                <div >

                </div>
                </>
                }
                </>
                
                }
            </div>
        )
    };
}

export default withRouter(GazpromNeft);