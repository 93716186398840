import React, { Component } from "react";
import { numberWithSpaces, withRouter, API_KEY } from "../GlobalFunctions"
import { LoadingOutlined, CameraOutlined, VideoCameraOutlined, CheckCircleOutlined, } from '@ant-design/icons';
import { Spin, Collapse, Form, Input, message, Table, Modal, ConfigProvider, } from 'antd'; 
import { Link } from "react-router-dom";
//import ReCAPTCHA from "react-google-recaptcha";
//import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";

const antIcon = ( <LoadingOutlined style={{ fontSize: 24, }} spin /> );

export class RexgenProof extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            data: null,
        };
    };

    componentDidMount() {
        this.getData();
    }; 

    componentDidUpdate() {
    
    };

    getData = () => {
            this.setState({data: JSON.parse(sessionStorage.getItem('proof')), isLoaded: true})
    };

render () {
    const { isLoaded, data } = this.state;
    const { translation } = this.props;
    

return (
    <ConfigProvider theme={{
        "components": {
          "Button": {
            "colorPrimary": "rgb(44,46,49)"
          }
        }
      }}>
    <div className="pt-4">{!isLoaded ?
      <div className="flex w-full h-[80vh] justify-center items-center">
        <Spin size="large" indicator={antIcon} />
      </div> : 
      <>
        
        <div className="w-full flex justify-center sm:text-[16px] md:text-[20px] py-2">{translation('vehicle_plate')}</div>
        <div className="w-full flex justify-center py-4">
                <div className="rounded-[5px] border-[2px] border-black px-4">    
                    <div className="text-[24px] font-bold tracking-wider">{this.props.params.plateNumber}</div>
                </div>
        </div>
        <div className="w-full flex justify-center sm:text-[16px] md:text-[20px] py-2">{translation('evidence')} {this.props.params.ticketNumber}</div>
        <div className="print:pt-0 print:w-full sm:pt-0 sm:w-full">
            {data.type==='photo' ? <img src={data.link}></img> 
            : <>
            <video controls autoplay playsinline muted name="media">
                <source src={data.link} type="video/mp4"></source>
            </video>
            </>}
        </div>
    </>
    }</div>
    </ConfigProvider>
);    
};
}

export default withRouter(RexgenProof);